<template>
    <div>
        <v-card>
            <v-card-text>
                <div class="text-subtitle-1 d-flex justify-space-between">
                    <div class="text-subtitle-1">{{moment('02-'+mes, 'DD-YYYY-MM').format('MMMM YYYY')}}</div>
                    <page-month
                        :mes.sync="mes"
                    />
                </div>

                <v-skeleton-loader
                    v-if="loading"
                    type="list-item-two-line, list-item-two-line, list-item-two-line"
                />

                <div v-if="!loading">
                    <v-row>
                        <v-col sm=6 md=4 lg=3 cols=12>
                            <v-sheet elevation=3 shaped class="pa-5 d-flex justify-space-between primary text-h6 white--text">
                                <div>Egresos del mes:</div><div>{{totalEgresos}}</div>
                            </v-sheet>
                        </v-col>
                        <v-col sm=6 md=4 lg=3 cols=12>
                            <v-sheet elevation=3 shaped class="pa-5 d-flex justify-space-between primary text-h6 white--text">
                                <div>Dias estancia:</div><div>{{diasEstancia}}</div>
                            </v-sheet>
                        </v-col>
                        <v-col sm=6 md=4 lg=3 cols=12>
                            <v-sheet elevation=3 shaped class="pa-5 d-flex justify-space-between primary text-h6 white--text">
                                <div>Prom días estancia:</div><div>{{(diasEstancia/totalEgresos).toFixed(2)}}</div>
                            </v-sheet>
                        </v-col>
                        <v-col sm=6 md=4 lg=3 cols=12>
                            <v-sheet elevation=3 shaped class="pa-5 d-flex justify-space-between primary text-h6 white--text">
                                <div>Int. de sustitución:</div><div>{{(((60*diasEsteMes)-parseFloat(diasEstancia))/totalEgresos).toFixed(2)}}</div>
                            </v-sheet>
                        </v-col>
                        <v-col sm=6 md=4 lg=3 cols=12>
                            <v-sheet elevation=3 shaped class="pa-5 d-flex justify-space-between primary text-h6 white--text">
                                <div>Ocupación hosp:</div><div>{{(parseFloat(diasEstancia)/(60*diasEsteMes)*100).toFixed(2)+'%'}}</div>
                            </v-sheet>
                        </v-col>
                        <v-col sm=6 md=4 lg=3 cols=12>
                            <v-sheet elevation=3 shaped class="pa-5 d-flex justify-space-between primary text-h6 white--text">
                                <div>Indice de rotación:</div><div>{{(totalEgresos/60).toFixed(2)}}</div>
                            </v-sheet>
                        </v-col>
                        <v-col sm=6 md=4 lg=3 cols=12>
                            <v-sheet elevation=3 shaped class="pa-5 d-flex justify-space-between primary text-h6 white--text">
                                <div>Quimioterapias:</div><div>{{quimios.length}}</div>
                            </v-sheet>
                        </v-col>
                        <v-col sm=6 md=4 lg=3 cols=12>
                            <v-sheet elevation=3 shaped class="pa-5 d-flex justify-space-between primary text-h6 white--text">
                                <div>Cir. Ambul.:</div><div>{{ambulatoria.length}}</div>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </div>

            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    mounted(){
        this.mes = this.moment(new Date()).format('YYYY-MM')
        this.carga()
    },
    components:{
        'page-month':()=>import('@/components/com/PageMonth'),
    },
    data:()=>({
        mes:'',
        egresos:[],
        quimios:[],
        ambulatoria:[],
        de:[],
        loading:false,
        diasEsteMes:0

    }),
    methods:{
        async carga(){
            this.loading=true
            this.diasEsteMes = new Date(this.mes.split('-')[0], this.mes.split('-')[1], 0).getDate();
            try{
                const req = await this.$http.get('/dashboard/indicadores',{params:{fecha:this.mes}})
                this.egresos=req.data.egresos
                this.quimios=req.data.quimios
                this.ambulatoria=req.data.ambulatoria
                this.loading=false
                //console.log(this.egresos)

                this.de = this.egresos.map(egr=>{
                    let crea = new Date(egr.dateCreated)
                    let alta = new Date(egr.fechaAlta)
                    let diferencia = (alta - crea)/(1000*60*60*24)
                    return Math.ceil(diferencia)
                })

            } catch(err) {
                this.loading=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    computed:{
        totalEgresos(){
            return(this.egresos.length)
        },
        diasEstancia(){
            let sum = this.de.reduce((previous, current) => current += previous, 0);
            //let avg = sum / this.diasEstancia.length;
            return (sum)
        }
    },
    watch:{
        mes(){
            this.carga()
        }
    },
}
</script>

<style>

</style>